import React, { useRef } from "react";
import { default as statPlus } from "../../../../../../assets/icon add-3.png";
import ImageComponent from '../../../../../../components/ImageCustomComponet';
import styles from './PhotoListItem.module.css';
import heic2any from "heic2any";
import { error }   from "../../../../../../components/notification";

export const PhotoListItem = ({  setLoading,url, width, height, index, deleteCallback, chooseFilesCallback }) => {
    const fileInputRef = useRef(null);
    const handleImageLoad = async (e) => {
        const files = Array.from(e.target.files);
        setLoading(true);
        const promises = files.map(async (file) => {
            if (file.type === "image/heic" || file.type === "image/heif") {
                try {
                    const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
                    return { 
                        url: URL.createObjectURL(convertedBlob), 
                        file: new File([convertedBlob], `${file.name.split('.')[0]}.jpg`, { type: 'image/jpeg' }) 
                    };
                } catch (erro) {
                    setLoading(false);
                    error('Загрузите фотографии формата PNG или JPEG');
                    console.error('Error converting HEIC to JPEG:', erro);
                    return null; 
                }
            } else {
                const imageUrl = URL.createObjectURL(file);
                return { url: imageUrl, file };
            }
        });
 
        const images = await Promise.all(promises);
        setLoading(false);
        chooseFilesCallback(images.filter(image => image !== null)); 
    };
    return (
        
        <div className={styles.wrapper} style={{ width: width }}>
            {url ?
                <ImageComponent imagePath={url} ourWidth={width} height={height} /> :
                <div className={styles.emptyItemWrapper} style={{ width, height }} htmlFor="addImageInput" onClick={() => fileInputRef?.current.click()}>
                    <img src={statPlus} alt="" />
                    {/* <span>Добавить фотографию</span> */}
                </div>
            }
            <input
                ref={fileInputRef}
                type="file"
                id="addImageInput"
                style={{ display: "none" }}
                multiple
                onChange={handleImageLoad}
                accept="image/png, image/jpeg, image/heic"
            />
            {url === null ? null : <button type="button" title="Удалить" onClick={() => deleteCallback(index)} />}
        </div>
    );
}