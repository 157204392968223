import React from 'react';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const DashboardChart = ({ data }) => {
    const aggregated = data.reduce((acc, item) => {
        const date = new Date(item.updated_at).toISOString().split('T')[0];
        if (!acc[date]) {
            acc[date] = { visits: 0, cartItemsTotal: 0 };
        }
        acc[date].visits += 1;
        acc[date].cartItemsTotal += item.cart_items_count;
        return acc;
    }, {});

    const dates = Object.keys(aggregated);
    if (dates.length === 0) {
        return <div>Нет данных для отображения</div>;
    }

    const maxDateStr = dates.reduce((max, date) => date > max ? date : max, dates[0]);
    const maxDate = new Date(maxDateStr);

    const startDate = new Date(maxDate);
    startDate.setDate(startDate.getDate() - 13);

    const daysArray = [];
    for (let i = 0; i < 14; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + i);
        const dateStr = currentDate.toISOString().split('T')[0];
        daysArray.push({
            date: dateStr,
            visits: aggregated[dateStr] ? aggregated[dateStr].visits : 0,
            cartItemsTotal: aggregated[dateStr] ? aggregated[dateStr].cartItemsTotal : 0,
        });
    }

    const firstNonZeroIndex = daysArray.findIndex(
        (day) => day.visits !== 0 || day.cartItemsTotal !== 0
    );

    const chartData = firstNonZeroIndex !== -1 ? daysArray.slice(firstNonZeroIndex) : daysArray;

    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart
                data={chartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" stroke="#90caf9" />
                <XAxis dataKey="date" stroke="#0d47a1" />
                <YAxis stroke="#0d47a1" />
                <Tooltip contentStyle={{ backgroundColor: "#e3f2fd", border: "none" }} />
                <Legend />
                <Line
                    type="monotone"
                    dataKey="visits"
                    stroke="#2196F3"
                    strokeWidth={2}
                    name="Посещения"
                />
                <Line
                    type="monotone"
                    dataKey="cartItemsTotal"
                    stroke="#64b5f6"
                    strokeWidth={2}
                    name="Интерес к товарам"
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default DashboardChart;
