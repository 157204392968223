import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import plus from "../../../assets/icon add.png";
import ShopItem from "./ShopListItemComponent_v2";
import styles from "./ShopsComponent_v2.module.css";

function ShopList({ shops, deleteHandler, viewHandler }) {
    const [storesLimit, setStoresLimit] = useState(1);
    const nav = useNavigate();

    const handlerForAddShop = () => {
        // if (tariff.design_choice) {
        //     nav('/store/set-design');
        // } else {
        //     nav('/store/new');
        // }
        if (shops.length < storesLimit)
            nav('/store/new');
        else
            nav('/tariff');

    };

    useEffect(() => {
        const savedConfig = localStorage.getItem("_sub_config");
        if (savedConfig) {
            const parsedConfig = JSON.parse(savedConfig);
            if (parsedConfig?.stores_count) {
                setStoresLimit(parsedConfig.stores_count);
            }
        }
    }, []);

    return (
        <div className={styles.shops}>
            <h1 className={styles.shopsTitle}>ваши магазины</h1>
            <div className={styles.shopsStorage}>
                {shops.length === 0 ? null :
                    shops.map(shop => <ShopItem key={uuid()} shop={shop} deleteHandler={deleteHandler} viewHandler={viewHandler} />)
                }

                <button onClick={handlerForAddShop} className={styles.shopAdd}>
                    <img src={plus} alt="plus" />
                    <span>{shops.length < storesLimit ? "Добавить новый магазин" : "Купить ещё магазины"}</span>
                </button>
            </div>
        </div>
    )
}

ShopList.defaultProps = {
    shops: []
}

export default ShopList;