import React from 'react';

const DynamicImageComponent = ({ normalImage, newYearImg, className, extraStylesForNewYearImg }) => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth();

    let imageSrc = normalImage;
    let extraStyle;

    if ((month === 11 && day >= 15) || (month === 0 && day <= 15)) {
        // С 15 декабря по 14 января
        imageSrc = newYearImg;
        extraStyle = extraStylesForNewYearImg === undefined ? { transform: "scale(1.4)" } : extraStylesForNewYearImg;
    } else {
        imageSrc = normalImage;
    }

    return (
        <img src={imageSrc} style={extraStyle} alt="Dynamic based on date" className={className} />
    );
};

export default DynamicImageComponent;