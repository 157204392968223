
import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';
import Loading from '../../components/Loading';
import NotificationComponent from '../../components/NotificationComponent';
import ProductList from '../../components/ProductsComponent_v2';

const PositionsViewPage = () => {
    const [positions, setPositions] = useState([]);
    const [notificationText, setNotificationText] = useState('');
    const [notificationState, setNotificationState] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tariffStatus, setTariffStatus] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('__product_page') ? Number(sessionStorage.getItem('__product_page')) : 1);
    const [sortParam, setSortParam] = useState({
        sortBy: null,
        orderBy: null,
    });
    const storeId = localStorage.getItem('current_store_id');

    const changePage = (page) => {
        sessionStorage.setItem('__product_page', page);
        setCurrentPage(page);
    };

    const handleDelete = (id) => {
        api.deleteProductV2(id)
            .then((response) => {
                setTariffStatus(true);
                setPositions((prevPositions) => prevPositions.filter((position) => position.id !== id));
            })
            .catch((error) => {
                console.error('Error deleting product:', error);
            });
    };

    const loadProducts = () => {
        setIsLoading(true);
        api.getProductsV2(currentPage, sortParam.sortBy, sortParam.orderBy)
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    setTariffStatus(response.data.tariff);
                    setPositions(response.data.products);
                    setTotalPages(response.data.total_pages);
                } else {
                    setNotificationText('Произошла ошибка...');
                    setNotificationState(true);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                setNotificationText('Произошла ошибка при загрузке...');
                setNotificationState(true);
                console.error('Error loading products:', err);
            });
    };
    useEffect(() => {
        if (localStorage.getItem('current_store_id')) {

            loadProducts();
        } else {
            setNotificationText('Выберите магазин для отображения списка товаров');
            setNotificationState(true);
        }
    }, []);

    useEffect(() => {
        if (notificationState) {
            const timer = setTimeout(() => {
                setNotificationState(false);
                setNotificationText('');
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [notificationState]);

    const handleSort = (type) => {
        const updatedSortOrder = sortParam.sortBy === type ? (sortParam.orderBy === 'asc' ? 'desc' : 'asc') : 'asc';
        setSortParam({ sortBy: type, orderBy: updatedSortOrder });
    };

    useEffect(() => {

        loadProducts();
    }, [currentPage, sortParam]);

    const handleSearch = (productName) => {
        if (storeId) {
            api.searchProducts(storeId, productName).then(r => {
                setCurrentPage(1);
                setTotalPages(r.data.total_pages);
                setPositions(r.data.products);
            }).catch((errors) => {
                console.log('Ошбика');
            })
        }
    };

    

    return (
        <div>
            <Header title={"Мои товары"} />
            {notificationState && <NotificationComponent text={notificationText} />}
            {localStorage.getItem('current_store_id') && (
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <ProductList
                        handleSearch={handleSearch}
                        products={positions}
                        setProducts={setPositions}
                        handleDelete={handleDelete}
                        setInfo={setNotificationText}
                        setIsInfo={setNotificationState}
                        tariff={tariffStatus}
                        handleSort={handleSort}
                        selectedSort={sortParam.sortBy}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={changePage}
                        setTariffStatus={setTariffStatus}
                    />
                </div>
            )}
            <Footer />
            {isLoading && <Loading />}
        </div>
    );
};

export default PositionsViewPage;