import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../api/api';
import logo from "../../assets/logo.svg";
import CustomCheckBox from "../../components/CustomCheckBoxComponent";
import { getUtmParams } from '../../components/getUtmParams';
import Loading from '../../components/Loading';
import { error, success } from '../../components/notification';
import extraStyle from "./LoginPage.module.css";
import PhoneInput from './PhoneInput';
import styles from './RegistrationPage.module.css';

const BoxberryRegistrationPage = () => {
    getUtmParams();
    function containsRussianLetters(text) {
        const russianLettersPattern = /[а-яё]/i;
        return russianLettersPattern.test(text);
    }

    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        orgName: null,
        userTg: '',
        referral_code: '',
        has_telegram: false,
        agreeTerms: false,
        agreePrivacyPolicy: false,
        config: {
            products_count: 300,
            stores_count: 1,
            analytics: true,
            marketing: false,
            premium_support: false,
            group_management: false,
            online_payment: true,
            delivery: true,
            ai_recommendation: true,
            remove_label: true,
            crm: false,
            no_cart: false,
            payments_interval: 3
        }
    });

    const [isLoading, setIsLoading] = useState(false);
    const nav = useNavigate();

    const [type, setType] = useState("Для физических лиц");

    const handleChange = event => {
        const { name, value, type, checked } = event.target;
        var newValue = type === 'checkbox' ? checked : value;

        if (name === 'username') {
            if (containsRussianLetters(newValue)) {
                event.target.classList.add(styles.formInputWrong);
                error('Используйте латинские буквы и цифры!');
            } else {
                event.target.classList.remove(styles.formInputWrong);
            }
        }

        setFormData(prevData => ({
            ...prevData,
            [name]: newValue,
        }));
    };

    const getAllUtm = () => {
        let check = localStorage.getItem('utmParams');
        if (check === null) {
            return null;
        } else {
            return JSON.parse(localStorage.getItem('utmParams'));
        }
    };

    const handleSubmit = event => {
        event.preventDefault();

        const isCompany = type === "Для физических лиц" ? false : true;
        const tel = formData.tel.replace(/\D/g, '');

        if (tel.length < 11) {
            error('Неверный номер телефона');
            return;
        }

        const dataToSend = {
            username: formData.email,
            email: formData.email,
            tel: formData.tel.replace(/\D/g, ''),
            first_name: formData.firstName,
            last_name: formData.lastName,
            tg_username: formData.userTg === '' ? null : formData.userTg.replace('@', ''),
            is_company: isCompany,
            company_name: formData.orgName,
            referral_code: formData.referral_code,
            has_telegram: formData.has_telegram,
            utm: getAllUtm(),
            config: formData.config
        };

        setIsLoading(true);
        api.registerAPI(dataToSend)
            .then((r) => {
                setIsLoading(false)
                if (r.status === 201) {
                    success('Пароль для входа отправлен на почту.');
                    nav('/login');
                } else {
                    error('Произошла ошибка, повторите попытку позже.')
                }

                if ((r.response.data.error.includes('email')) || r.response.data.email) {
                    error("Указанный email уже используется.");
                } else if (r.response.data.error.includes('username')) {
                    error("Пользователь с таким именем пользователя уже зарегестрирован.");
                }
            })
            .catch(error => { console.log(error) });
    };

    useEffect(() => {
        document.title = "Регистрация"
        inputTarget.current.focus();
        document.body.classList.add(styles.bodyBlock);

        const code = "33QG61";

        if (code) {
            setFormData(prevData => ({
                ...prevData,
                referral_code: code,
            }));
        }

        return () => {
            document.body.classList.remove(styles.bodyBlock);
        };
    }, []);

    const handlerForTypes = (e) => {
        for (let el of e.target.parentElement.children) {
            el.classList.remove(styles.activeType);
        }
        e.target.classList.add(styles.activeType);
        setType(e.target.textContent);
    };

    const inputTarget = useRef(null);
    return (
        <div className={styles.container}>
            <div className={styles.loginPagePreview} style={{ height: "100vh" }}>
                <div className={extraStyle.loginPagePreviewHeader}>
                    <h4>Добро пожаловать на платформу BOTIQUE!</h4>
                    <p>Botique - это веб-сервис для создания “умных” телеграмм магазинов с готовым сервисом для проведения онлайн-оплаты.</p>
                </div>
                <div className={extraStyle.loginPagePreviewFooter}>
                    <img src={logo} alt=''></img>
                    <span>2023-{new Date().getFullYear()} © Все права защищены</span>
                    <Link to={'https://docs.thebotique.ru/license-agreement'} target="_blank">Лицензионный договор</Link>
                    <Link to={'https://docs.thebotique.ru/terms-of-use'} target="_blank">Правила использования cервиса</Link>
                    <Link to={'https://docs.thebotique.ru/privacy-policy'} target="_blank">Политика обработки персональных данных</Link>
                </div>
            </div>
            <>
                <div className={styles.regContainer}>
                    <h2 className={styles.regTitle}>Регистрация</h2>
                    <div className={styles.choseTypeContainer}>
                        <div className={styles.activeType} onClick={handlerForTypes}>Для физических лиц</div>
                        <div onClick={handlerForTypes}>Для юридических лиц</div>
                    </div>
                    <form onSubmit={handleSubmit} className={styles.regForm}>
                        <div className={styles.regFormRow}>
                            <div className={styles.formGroup}>
                                <label className={styles.formLabel + extraStyle.extraTitle}>Почта</label>
                                <input ref={inputTarget} required
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={styles.formInput}
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label className={styles.formLabel + extraStyle.extraTitle}>Телефон</label>
                                <PhoneInput formData={formData} handleChange={handleChange} />
                            </div>
                        </div>

                        <div className={styles.regFormRow}>
                            <div className={styles.formGroup}>
                                <label className={styles.formLabel + extraStyle.extraTitle}>Имя</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className={`${styles.formInput} ${styles.nameInput}`}
                                />
                            </div>
                            <div className={styles.formGroup}>
                                <label className={styles.formLabel + extraStyle.extraTitle}>Фамилия</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className={`${styles.formInput} ${styles.nameInput}`}

                                />
                            </div>
                        </div>

                        <div className={styles.regFormRow}>
                            <div className={styles.formGroup}>
                                <label className={styles.formLabel + extraStyle.extraTitle}>Телграмм для уведомлений</label>
                                <input
                                    type="text"
                                    name="userTg"
                                    placeholder='@thebotique_ru'
                                    value={formData.userTg}
                                    onChange={handleChange}
                                    className={styles.formInput}
                                />
                            </div>
                        </div>

                        <div className={styles.regFormRow}>
                            {type === "Для физических лиц" ? null :
                                <div className={styles.formGroup}>
                                    <label className={styles.formLabel}>ИНН организации</label>
                                    <input required
                                        type="text"
                                        name="orgName"
                                        placeholder=''
                                        value={formData.orgName}
                                        onChange={handleChange}
                                        className={styles.formInput}
                                    />
                                </div>
                            }
                        </div>


                        <div className={styles.formGroup}>
                            <label className={styles.checkboxLabel}>
                                <CustomCheckBox required={true}
                                    name={"agreeTerms"}
                                    checked={formData.agreeTerms}
                                    onChange={handleChange}
                                />
                                <span>Соглашаюсь с </span>
                                <Link to={'https://docs.thebotique.ru/license-agreement'} target="_blank">лицензионным договором</Link>
                                <span> и </span>
                                <br />
                                <Link to={'https://docs.thebotique.ru/terms-of-use'} target="_blank">правилами использования сервиса</Link>
                            </label>
                        </div>
                        <div className={styles.formGroup}>
                            <label className={styles.checkboxLabel}>
                                <CustomCheckBox required={true}
                                    name={"agreePrivacyPolicy"}
                                    checked={formData.agreePrivacyPolicy}
                                    onChange={handleChange}
                                    className={styles.checkboxInput}
                                />
                                <>Даю </>
                                <Link to={'https://docs.thebotique.ru/privacy-policy-seller-agreement'} target="_blank">согласие на обработку персональных данных</Link>
                            </label>
                        </div>
                        <div className={styles.formGroup}>
                            <label className={styles.checkboxLabel}>
                                <CustomCheckBox required={false}
                                    name={"has_telegram"}
                                    checked={formData.has_telegram}
                                    onChange={handleChange}
                                />
                                <span>У меня есть Telegram канал</span>
                            </label>
                        </div>
                        <button className={styles.regBtn} onClick={() => window.ym(96127840, 'reachGoal', 'sendRegform1')}>Зарегистрироваться</button>
                    </form>
                    <p className={extraStyle.extraTitle}>
                        Уже есть аккаунт? <Link to={'/login'} className={extraStyle.extraTitle}>Войти</Link>
                    </p>
                </div>
            </>
            {isLoading ? <Loading /> : null}
        </div >

    );
};

export default BoxberryRegistrationPage;