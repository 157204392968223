import React, { useState } from 'react';
import styles from './CancelSubscriptionWizard.module.css';
import subscriptionApi from '../api/tariffApi';
import api from '../../../api/api';

const CancelSubscriptionWizard = () => {
    const [wizardActive, setWizardActive] = useState(false);

    const [step, setStep] = useState(1);

    const [wantsDiscount, setWantsDiscount] = useState(false);
    const [wantsManagerCall, setWantsManagerCall] = useState(false);
    const [cancelReason, setCancelReason] = useState('');

    const startWizard = () => {
        setWizardActive(true);
        setStep(1);
    };

    const nextStep = () => setStep((prev) => prev + 1);
    const prevStep = () => setStep((prev) => prev - 1);

    const handleCancelStep = () => {
        api.sendEmail({
            name: "CancelSubscriptionWizard",
            email: "cancel@thebotique.ru",
            message: `Этап 2 отмены подписки: ${wantsDiscount ? "нужна скидка," : ""} ${wantsManagerCall ? "нужен звонок менеджера" : ""}`
        })
    };

    const handleFinalCancel = () => {
        subscriptionApi.cancelSubscription({ cancel_reason: cancelReason }).then(() => {
            setWizardActive(false);
            setStep(1);
            setWantsDiscount(false);
            setWantsManagerCall(false);
            setCancelReason('');
            window.location.reload();
        });
    };

    const renderStep1 = () => (
        <div className={styles.step}>
            <h2>Вы действительно хотите отменить подписку?</h2>
            <p>
                После отмены вы потеряете доступ к премиум‑функциям.
                Мы будем очень грустить без вас.
            </p>
            <div className={styles.buttonsRow}>
                <button className={styles.keepButton} onClick={() => setWizardActive(false)}>
                    Нет, остаться
                </button>
                <button className={styles.nextButton} onClick={nextStep}>
                    Да, продолжить
                </button>
            </div>
        </div>
    );

    const renderStep2 = () => (
        <div className={styles.step}>
            <h2>Может, мы сможем помочь?</h2>
            <p>
                Если вам не хватает какой‑то функции или цена кажется слишком высокой,
                мы можем предложить <strong>скидку</strong> или <strong>звонок менеджера</strong>.
            </p>
            <div className={styles.options}>
                <label className={styles.checkboxLabel}>
                    <input
                        type="checkbox"
                        checked={wantsDiscount}
                        onChange={() => setWantsDiscount(!wantsDiscount)}
                    />
                    Хочу получить скидку
                </label>
                <label className={styles.checkboxLabel}>
                    <input
                        type="checkbox"
                        checked={wantsManagerCall}
                        onChange={() => setWantsManagerCall(!wantsManagerCall)}
                    />
                    Связаться с менеджером
                </label>
            </div>
            <div className={styles.buttonsRow}>
                <button className={styles.prevButton} onClick={prevStep}>
                    Назад
                </button>
                <button className={styles.nextButton} onClick={() => { nextStep(); handleCancelStep(); }}>
                    Далее
                </button>
            </div>
        </div>
    );

    const renderStep3 = () => (
        <div className={styles.step}>
            <h2>Почему вы хотите отменить подписку?</h2>
            <p>Мы стараемся улучшать сервис, и ваш ответ нам очень важен.</p>
            <select
                className={styles.select}
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
            >
                <option value="">Выберите причину...</option>
                <option value="price">Слишком дорого</option>
                <option value="notUsing">Редко пользуюсь</option>
                <option value="betterAlternative">Нашёл(ла) более удобный сервис</option>
                <option value="other">Другая причина</option>
            </select>
            <div className={styles.buttonsRow}>
                <button className={styles.prevButton} onClick={prevStep}>
                    Назад
                </button>
                <button
                    className={styles.nextButton}
                    onClick={cancelReason ? nextStep : undefined}
                    disabled={!cancelReason}
                >
                    Далее
                </button>
            </div>
        </div>
    );

    const renderStep4 = () => (
        <div className={styles.step}>
            <h2>Последний шаг</h2>
            <p>
                После отмены подписки вы потеряете доступ к премиум‑функциям.
                <br />
                Уверены, что хотите уйти?
            </p>
            {(wantsDiscount || wantsManagerCall) && (
                <p className={styles.highlight}>
                    Мы уже учли ваш запрос: {wantsDiscount && 'скидка '}
                    {wantsDiscount && wantsManagerCall && 'и '}
                    {wantsManagerCall && 'звонок менеджера'}
                </p>
            )}
            <div className={styles.buttonsRow}>
                <button className={styles.prevButton} onClick={prevStep}>
                    Назад
                </button>
                <button className={styles.cancelButton} onClick={handleFinalCancel}>
                    Отменить подписку
                </button>
            </div>
        </div>
    );

    return (
        <div className={styles.container}>
            {!wizardActive && (
                <button className={styles.cancelSubscriptionBtn} onClick={startWizard}>
                    Отменить подписку
                </button>
            )}

            {wizardActive && (
                <div className={styles.wizard}>
                    {step === 1 && renderStep1()}
                    {step === 2 && renderStep2()}
                    {step === 3 && renderStep3()}
                    {step === 4 && renderStep4()}
                </div>
            )}
        </div>
    );
};

export default CancelSubscriptionWizard;
