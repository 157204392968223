import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import api from "../../../api/api";
import FormatNumbers from "../../../components/FormatNumbersComponent";
import styles from "./MainComponent.module.css";
import ReferalInfoComponent from "./ReferalInfoComponent";
import TodoListComponent from "./TodoListComponent";
import { getCurrencySymbol } from "../../../components/currentCurrency";
function MainComponent({ user, activeShops, activeProducts, ordersStats, setInfo, setIsInfo, notificationsCount, notificationData, tariff, referral }) {
    const nav = useNavigate();
    const [newOrderStats, setnewOrderStats] = useState([]);
    const [activeDay, setActiveDay] = useState(730);
    const [days] = useState([7, 30, 730]);
    const contentStyle = { width: window.innerWidth >= 701 ? 559 : "80%", overflow: "hidden", borderRadius: 12, boxShadow: "0px 2px 8px 2px rgba(55, 55, 116, 0.15)", padding: 16, scrollbarWidth: 0, marginLeft: "200px", marginTop: 68, maxWidth: "555px", zIndex: 5 };
    const arrowStyle = { display: "block" };
    const statStyles = { color: "var(--blue, #0E86F8)", textAlign: "center", fontFamily: "Montserrat", fontSize: "16px", fontWeight: 600 };
    const handlerClick = (day) => {
        setActiveDay(day);
    };

    const mergeObjects = (() => {
        let storedObjs = [];

        return (obj) => {
            const newObj = { ...obj };
            for (let i = 0; i < storedObjs.length; i++) {
                const storedObj = storedObjs[i];
                if (storedObj.calculation_period === newObj.calculation_period) {
                    for (const key in newObj) {
                        if (key !== 'calculation_period' && storedObj.hasOwnProperty(key) && newObj.hasOwnProperty(key)) {
                            storedObj[key] += newObj[key];
                        } else {
                            storedObj[key] = newObj[key];
                        }
                    }
                    return storedObjs;
                }
            }

            storedObjs.push(newObj);
            return storedObjs;
        };
    })();


    const expirationDate = useMemo(() => {
        if (tariff.renewal_date) {
            const splittedDate = tariff.renewal_date.split('-');
            return `${splittedDate[2].slice(0, 2)}.${splittedDate[1]}.${splittedDate[0]}`
        }
    }, [tariff.renewal_date])

    const formattedContent = notificationData.content ? notificationData.content.replace(/<br>/g, '\n') : '';
    const maxLength = 256;
    const truncatedContent = formattedContent.length > maxLength ? `${formattedContent.slice(0, maxLength)} ...` : formattedContent;
    let finalStat;
    useEffect(() => {
        console.clear();
        api.getStoresList()
            .then((response) => {
                if (response.response) { if (response.response.data.code === "user_not_found") { localStorage.clear(); } }
                const storesData = response.data.stores.map((store) => store.id);
                storesData.forEach(id => {
                    api.getHomeStats(id).then(res => {
                        let data = res.data;
                        if (data === undefined) { return }
                        data.forEach(el => {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            finalStat = mergeObjects(el);
                            setnewOrderStats(finalStat);
                        })
                    }).catch(error => { console.log(error) })
                })
            })
            .catch(error => { console.log(error) });

        document.body.classList.add(styles.bodyBlock);

        return () => {
            document.body.classList.remove(styles.bodyBlock);
        };
    }, []);
    const hasZeroOrdersFor730 = (orders) => {
        return orders.some(order => order.calculation_period === 730 && order?.total_orders === 0);
    };
    let checkOrdersAmount = hasZeroOrdersFor730(newOrderStats);

    return (
        <div className={styles.container}>
            <Popup trigger={<button className={styles.popupBtn}><b>Уведомления ({notificationsCount})</b></button>} {...{ contentStyle, arrowStyle }} position="left center" on="hover" mouseEnterDelay={200}>

                <div className={styles.popupContent}>
                    <span style={{ fontWeight: 600 }}>Последнее уведомление:</span>
                    <button className={styles.shopsBtn} onClick={() => { nav('/notifications') }}>
                        <span style={{ width: window.innerWidth >= 701 ? 190 : 150 }}>Все уведомления</span>
                    </button>
                </div>

                {notificationsCount > 0 ? (
                    <p className={styles.tooltip}>
                        <span className={styles.text}>{notificationData.title}</span><br></br>
                        <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />
                    </p>
                ) : (
                    <p className={styles.tooltip} style={{ textAlign: "center" }}>
                        <span className={styles.text}>Новых уведомлений пока не было</span><br></br>
                        {/* <div dangerouslySetInnerHTML={{ __html: truncatedContent }} /> */}
                    </p>
                )}
            </Popup>
            <h1 className={styles.title}>
                Личный кабинет продавца
            </h1>
            <span className={styles.text}>Добро пожаловать, {user}!</span>
            <div className={styles.blockStorage}>
                <div className={styles.statisticBlock}>
                    <span className={styles.miniTitle}>Статистика кабинета:</span>
                    <div className={styles.statisticSection}>
                        <div className={styles.statisticSectionShops}>
                            <span>Магазинов:</span>
                            <span>Товаров:</span>

                        </div>
                        {tariff.stores_count && <div className={styles.statisticSectionShops}>
                            <FormatNumbers number={`${activeShops}/${tariff.stores_count}`} />
                            <FormatNumbers
                                number={`${activeProducts}/${tariff.products_count < 1000 ? tariff.products_count * tariff.stores_count : "∞"}`}
                            />
                        </div>}
                        <div className={styles.statisticSectionShops}>
                            <button className={styles.shopsBtn} onClick={() => { nav('/stores') }}>
                                <span>Все магазины</span>
                            </button>
                            <button className={styles.shopsBtn} onClick={() => { nav("/positions") }}>
                                <span>Все товары</span>
                            </button>
                        </div>
                    </div>
                    <div className={styles.statisticInfo}>
                        <span className={styles.miniTitle}>Статистика продаж:</span>
                        <div className={styles.statisticBtns}>
                            {days.map(el => {
                                if (el === 7) {
                                    return <button onClick={() => handlerClick(el)} className={activeDay === el ? styles.activeBtn : null}>неделя</button>
                                }
                                if (el === 30) {
                                    return <button onClick={() => handlerClick(el)} className={activeDay === el ? styles.activeBtn : null}>месяц</button>
                                }
                                if (el === 730) {
                                    return <button onClick={() => handlerClick(el)} className={activeDay === el ? styles.activeBtn : null}>за всё время</button>
                                }
                            })}
                        </div>
                        {!checkOrdersAmount && <div className={styles.stats}>
                            <div className={styles.statsItem}>
                                <span>Всего заказов:</span>
                                {/* <span>{ordersStats?.count}</span> */}
                                {newOrderStats.map(el => {
                                    if (el.calculation_period === activeDay) {
                                        return <FormatNumbers number={el?.total_orders} styles={statStyles} />
                                    }
                                })}
                            </div>
                            <div className={styles.statsItem}>
                                <span>Заказов на сумму:</span>
                                {/* <span>{ordersStats?.sum}</span> */}
                                {newOrderStats.map(el => {
                                    if (el.calculation_period === activeDay) {
                                        return <FormatNumbers number={`${(el?.order_amount / 100).toFixed()} ${getCurrencySymbol(localStorage.getItem("current_store_currency"))}`} styles={statStyles} />
                                    }
                                })}
                            </div>
                            <div className={styles.statsItem}>
                                <span>Количество покупателей:</span>
                                {/* <span>{ordersStats?.buyers}</span> */}
                                {newOrderStats.map(el => {
                                    if (el.calculation_period === activeDay) {
                                        return <FormatNumbers number={`${el?.total_customers}`} styles={statStyles} />
                                    }
                                })}
                            </div>
                            <div className={styles.statsItem}>
                                <span>Средний чек:</span>
                                {/* <span>{ordersStats?.check}</span> */}
                                {newOrderStats.map(el => {
                                    if (el.calculation_period === activeDay) {
                                        return <FormatNumbers number={`${(el?.average_check / 100).toFixed()} ${getCurrencySymbol(localStorage.getItem("current_store_currency"))}`} styles={statStyles} />
                                    }
                                })}

                            </div>
                        </div>}
                    </div>
                    {!checkOrdersAmount && <button onClick={() => { nav("/orders") }} className={styles.orderBtn}>Перейти на страницу заказов</button>}
                    <div className={styles.tariffsInfoBlock}>
                        <div className={styles.tariffsInfoBlockTitle}>
                            <span className={styles.miniTitle}>Ваш тариф:</span>
                            <span>{tariff.status ? "Активен" : "Неактивен"}</span>
                        </div>
                        {tariff.status ?
                            <div className={styles.tariffsInfoBlockExpirationTime}>
                                {new Date(tariff.renewal_date) >= new Date() ? <>
                                    <span className={styles.miniTitle}>Действует до:</span>
                                    <span>{expirationDate}</span>
                                </> : <span onClick={() => { nav("/tariff") }} className={styles.tariffsInfoBlockLink}>Продлить подписку</span>}
                            </div> :
                            <div>
                                Собери свой тариф – выбери нужное и плати только за то, что используешь! <span onClick={() => { nav("/tariff") }} className={styles.tariffsInfoBlockLink}>Собрать свой тариф</span>
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.refTodoBlock}>
                    <TodoListComponent setInfo={setInfo} setIsInfo={setIsInfo} />
                    <ReferalInfoComponent referral={referral} />
                </div>

                <div className={styles.btnsBlock}>
                    <button className={styles.btnBlock} onClick={() => { activeShops + 1 <= tariff.stores ? (tariff.design_choice ? nav(`/store/set-design`) : nav(`/store/new`)) : (nav(`/stores`)) }}>
                        <div></div>
                        <span>Добавить новый <span style={{ fontWeight: 600 }}>магазин</span></span>
                    </button>
                    <button className={styles.btnBlock} onClick={() => { activeProducts + 1 <= tariff.products * activeShops ? (nav("/product/new")) : (nav(`/positions`)) }}>
                        <div></div>
                        <span>Добавить новый <span style={{ fontWeight: 600 }}>товар</span></span>
                    </button>
                    <button className={styles.btnBlock} onClick={() => { nav("/create-banner") }}>
                        <div></div>
                        <span>Добавить <span style={{ fontWeight: 600 }}>баннер/акцию</span></span>
                    </button>
                    <div className={styles.help}>
                        <span>Есть вопросы по работе сервиса?</span>
                        <span>Не знаете с чего начать?</span>
                        <span>Возникли какие-либо сложности?</span>
                        <Link to={'/support'}>Обратитесь в поддержку</Link>
                        <span>или</span>
                        <Link to={'/support'}>посмотрите видеоинструкции</Link>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MainComponent;