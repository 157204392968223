import React, { useEffect, useState } from 'react';
import Footer from '../../components/FooterComponent_v2';
import Header from '../../components/HeaderComponent_v2';

import subscriptionApi from './api/tariffApi';
import CancelSubscriptionWizard from './components/CancelSubscriptionWizard';
import TariffActiveDate from './components/TariffActiveDate';
import TariffBlockV2 from './components/TariffBlockV2';
import styles from './TariffPage.module.css';

function TarfiffPage() {
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        subscriptionApi.getSubscriptionDetails()
            .then(({ data }) => {
                setSubscriptionDetails(data);
                setLoaded(true);
            })
            .catch((err) => {
                setError(err);
                setLoaded(true);
            });
    }, []);

    return (
        <>
            <Header title="Конструктор тарифного плана" />
            <div className={styles.pageContainer}>
                <div className={styles.contentWrapper}>
                    <h1 className={styles.pageTitle}>Ваш конструктор тарифа Botique</h1>
                    {loaded && subscriptionDetails && <TariffBlockV2 subscription={subscriptionDetails} />}
                    {loaded && subscriptionDetails.status && <TariffActiveDate activeUntil={subscriptionDetails.renewal_date} />}
                    {loaded && subscriptionDetails.status && <CancelSubscriptionWizard />}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default TarfiffPage;
