import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../api/api";
import copy from "../../../assets/icon-copy.png";
import settingsIcon from '../../../assets/settings_icon.svg';
import PopupCustomComponent from "../../../components/PopupCustomComponent";
import styles from "./ShopListItemComponent_v2.module.css";

function ShopItem({ shop, deleteHandler, viewHandler }) {

    const [showTooltip, setShowTooltip] = useState(false);
    const [imgSrc, setImgSrc] = useState(null);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(shop.link);
        setPopUpInfo('Ссылка скопирована в буфер');
    };

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
        setPopUpInfo('Скопируйте ссылку для интеграции магазина в телеграмм канал');
    };

    const [popUpInfo, setPopUpInfo] = useState('Скопируйте ссылку для интеграции магазина в телеграмм канал');

    useEffect(() => {
        if (shop.link) {
            api.getQRcode({ 'url': shop.link }).then((response) => {
                if (response.data) {
                    const responseData = response.data;
                    const qrCodeBase64 = responseData.qr_code_image;
                    setImgSrc("data:image/png;base64," + qrCodeBase64);
                }
            }).catch((e) => { console.log(e) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={styles.shop}>
                <div className={styles.shopRow}>
                    <div className={styles.qrWrapper}>
                        {imgSrc ? (
                            <img className={styles.qrImage} src={imgSrc} alt="QR Code" />
                        ) : (
                            <div className={styles.qrPlaceholder}>QR Code</div>
                        )}
                    </div>
                    <div className={styles.shopInfo}>
                        <div className={styles.titleContainer}>
                            <span>{shop.name}</span>
                            <Link to={`/store-settings/${shop.id}`}><img src={settingsIcon} alt="настройка" /></Link>
                        </div>
                        {shop.link ?
                            <div className={styles.links} >
                                <span className={styles.shopInfoHref} onClick={() => window.open(shop.link, '_blank')}>Ссылка на мой магазин</span>
                                <img className={styles.shopInfoImg} src={copy} alt="" onClick={handleCopyLink} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ position: 'relative', display: 'inline-block', cursor: "pointer" }}></img>
                                {showTooltip && (<div className={styles.InfoNotification}>{popUpInfo}</div>)}
                            </div> :
                            <span className={styles.storeLinkDisabled}>
                                Ссылка на магазин доступна после <Link to={'/tariff'}>выбора тарифа и оплаты подписки</Link>
                            </span>
                        }
                    </div>
                </div>
                <div className={styles.shopRow}>
                    <button onClick={() => viewHandler(shop.id)} className={styles.shopLook}>Посмотреть</button>
                    <PopupCustomComponent
                        triggerEl={<button className={styles.shopDelete}>Удалить</button>}
                        title={"Вы действительно хотите удалить магазин?"}
                        text={"После удаления восстановить магазин не получится"}
                        handler={deleteHandler}
                        paramsHandler={shop.id}
                    />
                </div>
            </div>
        </>
    )
}
export default ShopItem;