import React from 'react';
import styles from './RecalculationModal.module.css';

const RecalculationModal = ({ currentCost, newCost, onConfirm, onCancel }) => {
    const difference = newCost - currentCost;
    const isAdditionalPayment = difference > 0;
    const isDecrease = difference < 0;

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <h2>Пересчет тарифа</h2>
                <p>
                    Текущая стоимость: <span className={styles.cost}>{currentCost} ₽</span>
                </p>
                <p>
                    Новая стоимость: <span className={styles.cost}>{newCost} ₽</span>
                </p>
                {isAdditionalPayment && (
                    <p className={styles.additional}>
                        Требуется доплата: <span className={styles.difference}>{difference} ₽</span>
                    </p>
                )}
                {isDecrease && (
                    <p className={styles.decrease}>
                        Новая стоимость ниже текущей. При следующем списании будет списываться{' '}
                        <span className={styles.newCost}>{newCost} ₽</span>
                    </p>
                )}
                {(!isAdditionalPayment && !isDecrease) && (
                    <p className={styles.same}>Стоимость не изменилась</p>
                )}
                <div className={styles.buttonsRow}>
                    <button className={styles.cancelButton} onClick={onCancel}>
                        Отменить
                    </button>
                    <button className={styles.confirmButton} onClick={onConfirm}>
                        Далее
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RecalculationModal;
