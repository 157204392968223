export const getCurrencySymbol = (currencyCode) => {
    const symbols = {
        KZT: '₸', // Тенге
        AMD: '֏', // Драм
        GEL: '₾', // Лари
        BYN: 'Br', // Белорусский рубль
        USD: '$',  // Доллар США
        EUR: '€',  // Евро
        RUB: '₽',  // Русский рубль
        UZS: "Soʻm"//узбекского сума 
    };

    if (currencyCode) {
        return symbols[currencyCode] || "₽";
    }     
    return "₽";
};