import React, { useState } from 'react';
import api from '../../../api/api';
import { error, success } from '../../../components/notification';
import styles from './AMOcrmIntegration.module.css';

const AMOcrmIntegration = () => {
    const [subdomain, setSubdomain] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [clientID, setClientID] = useState('');
    const [redirectUri] = useState('https://admin.thebotique.ru/crm/callback');

    const handleSubmit = () => {
        if (!localStorage.getItem("current_store_id")) {
            error("Создайте магазин для подключения к CRM");
            return;
        }

        if (!clientID.trim() || !subdomain.trim() || !secretKey.trim() || !apiKey.trim()) {
            error("Заполните все поля");
            return;
        }


        const integrationData = {
            client_id: clientID,
            amo_crm_url: subdomain,
            client_secret: secretKey,
            redirect_uri: redirectUri,
            code: apiKey,
        };
        api.connectAmoCrm(integrationData).then((r) => {
            if (r.data)
                success("Интеграция подключена успешно");
            else if (r.response.data)
                error('Произошла ошибка, повторите позже');

        }).catch(() => error('Произошла ошибка, повторите позже'));
    };

    const handleSubdomainChange = (e) => {
        let inputValue = e.target.value.trim();

        inputValue = inputValue.replace(/^https?:\/\//, '');

        inputValue = inputValue.split('/')[0];

        inputValue = inputValue.split(':')[0];

        const suffix = '.amocrm.ru';
        if (inputValue.endsWith(suffix)) {
            inputValue = inputValue.slice(0, -suffix.length);
        }

        setSubdomain(inputValue);
    };

    return (
        <div className={styles.card}>
            <h4 className={styles.cardTitle}>Интеграция с amoCRM</h4>

            <div className={styles.formGroup}>
                <label>Ссылка для перенаправления:</label>
                <div className={styles.inputGroup}>
                    <input
                        type="text"
                        readOnly
                        value={redirectUri}
                    />
                    <button
                        className={styles.btnOutline}
                        type="button"
                        onClick={() => {
                            navigator.clipboard.writeText(redirectUri)
                                .then(() => {
                                    console.log('Значение скопировано в буфер обмена');
                                })
                                .catch((err) => {
                                    console.error('Ошибка при копировании:', err);
                                });
                        }}
                    >
                        Копировать
                    </button>
                </div>
                <div className={styles.info}>
                    <p>
                        При создании новой интеграции обязательно укажите это значение в поле <strong>"Ссылка для перенаправления"</strong>.
                    </p>
                </div>
            </div>

            <div className={styles.formGroup}>
                <label>ID интеграции:</label>
                <input
                    type="text"
                    placeholder="15251952-612c-4dcb-9992-e08677cc39d1"
                    value={clientID}
                    onChange={(e) => setClientID(e.target.value)}
                />
            </div>

            <div className={styles.formGroup}>
                <label>Поддомен amoCRM:</label>
                <input
                    type="text"
                    placeholder="example.amocrm.ru"
                    value={subdomain}
                    onChange={handleSubdomainChange}
                />
            </div>

            <div className={styles.formGroup}>
                <label>Секретный ключ:</label>
                <input
                    type="password"
                    placeholder="Введите секретный ключ"
                    value={secretKey}
                    onChange={(e) => setSecretKey(e.target.value)}
                />
            </div>

            <div className={styles.formGroup}>
                <label>Код авторизации:</label>
                <input
                    type="password"
                    placeholder="Введите код авторизации"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                />
            </div>

            <button className={styles.btnOutline} onClick={handleSubmit}>
                Сохранить настройки
            </button>
        </div>
    );
};

export default AMOcrmIntegration;
