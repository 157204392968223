import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../api/api';
import styles from "../styles/MobileMenuComponent.module.css";
function BurgerMenu({ options, selectedOption, activePartMenu, isMarketing, isGroups }) {
    const nav = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option) => {
        setIsOpen(false);
        localStorage.setItem('current_store_id', option.id);
        const urlPath = window.location.pathname.split('/');
        if (urlPath[1] === 'store' & urlPath.length === 3) {
            if (urlPath[2] !== 'new' & urlPath[2] !== 'set-design') {
                nav(`/store/${option.id}`);
            }
        }
        window.location.reload();
    };

    const handlerForAddShop = () => {
        nav('/stores');
    };
    const handleExit = () => {
        api.logoutAPI().then((response) => {
            localStorage.clear();
            nav('/');
            window.location.reload();
        }).catch(error => { console.log(error) });
    };
    return (
        <Fragment>
            <button className={isOpen ? styles.closeBurger : styles.openBurger} onClick={() => toggleDropdown()}></button>
            {isOpen && (
                <div className={styles.menuContainer}>
                    <div className={styles.menuBlock}>
                        <div className={styles.menuBlockTitle}>Магазины</div>
                        <ul className={styles.menuList}>
                            <li onClick={handlerForAddShop}>+ Создать магазин</li>
                            {options.map((option, index) => (
                                <li className={styles.menuListItem} key={index} onClick={() => selectOption(option)} style={option.name === selectedOption ? { color: "#3CADFF" } : null}>
                                    {option.name}
                                </li>
                            ))}
                            <li className={styles.menuListItem} onClick={() => { window.location = '/stores' }}>Все магазины</li>
                        </ul>

                    </div>
                    <div className={styles.menuBlock}>
                        <div className={styles.menuBlockTitle}>Меню</div>
                        <ul className={styles.menuList}>
                            <li className={styles.menuListItem}><Link to={"/"} style={activePartMenu === "Главная" ? { color: "#3CADFF" } : null}>Главная</Link></li>
                            <li className={styles.menuListItem}><Link to={"/positions"} style={activePartMenu === "Мои товары" ? { color: "#3CADFF" } : null}>Товары</Link></li>
                            {isGroups && <li className={styles.menuListItem} ><Link to={"/product-groups"} style={activePartMenu === "Управление группами товаров" ? { color: "#3CADFF" } : null}>Группы товаров</Link></li>}
                            <li className={styles.menuListItem} ><Link to={"/banners"} style={activePartMenu === "Мои баннеры" ? { color: "#3CADFF" } : null}>Баннеры и акции</Link></li>
                            <li className={styles.menuListItem} ><Link to={"/orders"} style={activePartMenu === "Страница заказов" ? { color: "#3CADFF" } : null}>Заказы</Link></li>
                            {isMarketing && <li className={styles.menuListItem} ><Link to={"/marketing"} style={activePartMenu === "Клиенты и рассылки" ? { color: "#3CADFF" } : null}>Клиенты и рассылки</Link></li>}
                            <li className={styles.menuListItem}><Link to={"/support"} style={activePartMenu === "Поддержка" ? { color: "#3CADFF" } : null}>Поддержка</Link></li>
                        </ul>
                    </div>
                    <div className={styles.menuBlock}>
                        <div className={styles.menuBlockTitle}>Аккаунт</div>
                        <ul className={styles.menuList}>
                            <li className={styles.menuListItem} onClick={() => nav('/profile')}>
                                Профиль
                            </li>
                            <li onClick={() => nav('/profile')} className={styles.menuListItem}>
                                Настройки
                            </li>
                            <li onClick={() => nav('/tariff')} className={styles.menuListItem}>
                                Конструктор тарифа
                            </li>
                            <li onClick={() => handleExit()} className={styles.menuListItem}>
                                Выйти
                            </li>
                        </ul>
                    </div>

                </div>

            )}

        </Fragment>)

}
export default BurgerMenu;