import axios from 'axios';

// const baseAPIURL = 'http://localhost:8000';
const baseAPIURL = 'https://api-admin.thebotique.ru';

const subscriptionApi = {
    // Детали подписки
    getSubscriptionDetails: async () => {
        try {
            const response = await axios.get(`${baseAPIURL}/api/v1/subscription/details/`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    // Конфигурация подписки
    getSubscriptionConfig: async () => {
        try {
            const response = await axios.get(`${baseAPIURL}/api/v1/subscription/config/`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    // Подписаться
    subscribeToSubscription: async (data) => {
        try {
            const response = await axios.post(
                `${baseAPIURL}/api/v1/subscription/subscribe/`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    },
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    },

    // Отменить подписку
    cancelSubscription: async (data) => {
        try {
            const response = await axios.put(
                `${baseAPIURL}/api/v1/subscription/cancel/`,
                {data},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    },
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
};

export default subscriptionApi;
