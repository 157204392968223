import React, { useEffect, useState } from 'react';
import subscriptionApi from '../api/tariffApi';
import ConfirmModal from './ConfirmModal';
import RecalculationModal from './RecalculationModal';
import styles from './TariffBlockV2.module.css';

const TariffBlockV2 = ({ subscription }) => {
    const [currentConfig, setCurrentConfig] = useState(null);
    const [editedConfig, setEditedConfig] = useState(null);
    const [isModalRecalcVisible, setModalRecalcVisible] = useState(false);
    const [isModalConfirmVisible, setModalConfirmVisible] = useState(false);

    useEffect(() => {
        if (subscription) {
            const initialConfig = {
                products: subscription.config.products_count || 50,
                shops: subscription.config.stores_count || 1,
                options: {
                    analytics: subscription.config.analytics,
                    marketing: subscription.config.marketing,
                    premiumSupport: subscription.config.premium_support,
                    groupManagement: subscription.config.group_management,
                    onlinePayment: subscription.config.online_payment,
                    delivery: subscription.config.delivery,
                    aiRecommendation: subscription.config.ai_recommendation,
                    removeLabel: subscription.config.remove_label,
                    crm: subscription.config.crm,
                    noCart: subscription.config.no_cart,
                    period: subscription.config.payments_interval || 1,
                },
                status: subscription.status,
                currentPrice: subscription.price ? subscription.price / 100 : 399,
            };

            setCurrentConfig(initialConfig);
            setEditedConfig({ ...initialConfig, options: { ...initialConfig.options } });
        }
    }, [subscription]);

    if (!currentConfig || !editedConfig) {
        return <div></div>;
    }

    const discountMapping = { 1: subscription.discount, 3: 5, 6: 10, 12: 20 };
    const baseShopFee = 99;
    let extrasCost = 0;
    const opts = editedConfig.options;
    if (opts.analytics) extrasCost += 100;
    if (opts.marketing) extrasCost += 1000;
    if (opts.premiumSupport) extrasCost += 2500;
    if (opts.groupManagement) extrasCost += 500;
    if (opts.onlinePayment) extrasCost += 300;
    if (opts.delivery) extrasCost += 250;
    if (opts.aiRecommendation) extrasCost += 200;
    if (opts.removeLabel) extrasCost += 50;
    if (opts.crm) extrasCost += 1000;
    if (opts.noCart) extrasCost += 450;

    const productCost = editedConfig.products * 1;
    const singleShopCost = baseShopFee + productCost + extrasCost;
    const monthlyCost = singleShopCost * editedConfig.shops;
    const period = editedConfig.options.period;
    const discountFactor = 1 - (discountMapping[period] || 0) / 100;
    const newTotalCost = Math.round(monthlyCost * period * discountFactor);

    const handleChange = (field, value) => {
        setEditedConfig((prev) => ({ ...prev, [field]: value }));
    };

    const handleOptionChange = (option, value) => {
        setEditedConfig((prev) => ({
            ...prev,
            options: { ...prev.options, [option]: value },
        }));
    };

    const handleConnect = () => {
        const updatedConfig = {
            config: {
                products_count: editedConfig.products,
                stores_count: editedConfig.shops,
                analytics: editedConfig.options.analytics,
                marketing: editedConfig.options.marketing,
                premium_support: editedConfig.options.premiumSupport,
                group_management: editedConfig.options.groupManagement,
                online_payment: editedConfig.options.onlinePayment,
                delivery: editedConfig.options.delivery,
                ai_recommendation: editedConfig.options.aiRecommendation,
                remove_label: editedConfig.options.removeLabel,
                crm: editedConfig.options.crm,
                no_cart: editedConfig.options.noCart,
                payments_interval: editedConfig.options.period,
            },
            price: newTotalCost * 100,
        };

        subscriptionApi.subscribeToSubscription(updatedConfig).then(r => { window.location.href = r.data.pay_url; });
    };

    return (
        <div className={styles.container}>
            <div className={styles.summarySection}>
                <div className={styles.summaryCard}>
                    <div className={styles.summaryTitle}>
                        Ваша стоимость за {period} {period === 1 ? 'месяц' : 'месяцев'}
                    </div>
                    <div className={styles.periodButtons}>
                        {[1, 3, 6, 12].map((p) => (
                            <button
                                key={p}
                                className={`${styles.periodButton} ${period === p ? styles.activePeriod : ''}`}
                                onClick={() => handleOptionChange('period', p)}
                            >
                                {p} {p === 1 ? 'месяц' : p === 3 ? 'месяца' : 'месяцев'}
                            </button>
                        ))}
                    </div>
                    <div className={styles.priceRow}>
                        <div className={styles.price}>
                            <span className={styles.priceValue}>{newTotalCost}</span>
                            {currentConfig.status && currentConfig.options.period === period && newTotalCost !== currentConfig.currentPrice && (
                                <span className={`${styles.priceValue} ${styles.crossedPrice}`}>
                                    {currentConfig.currentPrice}
                                </span>
                            )}
                            <span className={styles.priceCurrency}>₽</span>
                        </div>
                        {(period > 1 || subscription.discount > 0) && (
                            <div className={styles.discountInfo}>
                                Скидка {discountMapping[period]}%
                            </div>
                        )}
                    </div>

                    {!currentConfig.status && (
                        <button className={styles.connectButton} onClick={() => setModalConfirmVisible(true)}>
                            Подключить
                        </button>
                    )}

                    {currentConfig.status && currentConfig.currentPrice !== newTotalCost && (
                        <button
                            className={styles.connectButton}
                            onClick={() => setModalRecalcVisible(true)}
                        >
                            Пересчитать
                        </button>
                    )}
                </div>
            </div>

            <div className={styles.configSection}>
                <div className={styles.block}>
                    <h3 className={styles.blockTitle}>Настройка магазинов и товаров</h3>
                    <div className={styles.slidersRow}>
                        <div className={styles.sliderColumn}>
                            <label className={styles.sliderLabel}>Количество магазинов</label>
                            <div className={styles.sliderGroup}>
                                <div className={styles.sliderValue}>{editedConfig.shops}</div>
                                <input
                                    type="range"
                                    min="0"
                                    max="10"
                                    value={editedConfig.shops}
                                    onChange={(e) => handleChange('shops', Number(e.target.value))}
                                    className={styles.rangeInput}
                                />
                            </div>
                        </div>
                        <div className={styles.sliderColumn}>
                            <label className={styles.sliderLabel}>Количество товаров</label>
                            <div className={styles.sliderGroup}>
                                <div className={styles.sliderValue}>
                                    {editedConfig.products > 950 ? 'без ограничений' : editedConfig.products}
                                </div>
                                <input
                                    type="range"
                                    min="50"
                                    max="1000"
                                    step="50"
                                    value={editedConfig.products}
                                    onChange={(e) => handleChange('products', Number(e.target.value))}
                                    className={styles.rangeInput}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.block}>
                    <h3 className={styles.blockTitle}>Дополнительные опции</h3>
                    <div className={styles.optionsGrid}>
                        <div className={styles.optionCard}>
                            <div>
                                <div className={styles.optionLabel}>
                                    Аналитика
                                    <span
                                        className={styles.tooltipIcon}
                                        data-tooltip="Отслеживание конверсии и аналитика продаж"
                                    >
                                        ?
                                    </span>
                                </div>
                                <div className={styles.optionSub}>+100 руб.</div>
                            </div>
                            <label className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={editedConfig.options.analytics}
                                    onChange={() =>
                                        handleOptionChange('analytics', !editedConfig.options.analytics)
                                    }
                                />
                                <span className={styles.sliderSwitch}></span>
                            </label>
                        </div>
                        <div className={styles.optionCard}>
                            <div>
                                <div className={styles.optionLabel}>
                                    <span className={styles.labelText}>Маркетинговые рассылки</span>
                                    <span
                                        className={styles.tooltipIcon}
                                        data-tooltip="Управление рассылками для уведомлений"
                                    >
                                        ?
                                    </span>
                                </div>
                                <div className={styles.optionSub}>+1000 руб.</div>
                            </div>
                            <label className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={editedConfig.options.marketing}
                                    onChange={() =>
                                        handleOptionChange('marketing', !editedConfig.options.marketing)
                                    }
                                />
                                <span className={styles.sliderSwitch}></span>
                            </label>
                        </div>
                        <div className={styles.optionCard}>
                            <div>
                                <div className={styles.optionLabel}>
                                    <span className={styles.labelText}>Премиум поддержка</span>
                                    <span
                                        className={styles.tooltipIcon}
                                        data-tooltip="Приоритетная поддержка с персональным менеджером"
                                    >
                                        ?
                                    </span>
                                </div>
                                <div className={styles.optionSub}>+2500 руб.</div>
                            </div>
                            <label className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={editedConfig.options.premiumSupport}
                                    onChange={() =>
                                        handleOptionChange('premiumSupport', !editedConfig.options.premiumSupport)
                                    }
                                />
                                <span className={styles.sliderSwitch}></span>
                            </label>
                        </div>
                        <div className={styles.optionCard}>
                            <div>
                                <div className={styles.optionLabel}>
                                    Онлайн-оплата
                                    <span
                                        className={styles.tooltipIcon}
                                        data-tooltip="Подключение онлайн-оплаты с отправкой чеков в налоговую"
                                    >
                                        ?
                                    </span>
                                </div>
                                <div className={styles.optionSub}>+300 руб.</div>
                            </div>
                            <label className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={editedConfig.options.onlinePayment}
                                    onChange={() =>
                                        handleOptionChange('onlinePayment', !editedConfig.options.onlinePayment)
                                    }
                                />
                                <span className={styles.sliderSwitch}></span>
                            </label>
                        </div>
                        <div className={styles.optionCard}>
                            <div>
                                <div className={styles.optionLabel}>
                                    Доставка
                                    <span
                                        className={styles.tooltipIcon}
                                        data-tooltip="Интеграция с Boxberry для доставки"
                                    >
                                        ?
                                    </span>
                                </div>
                                <div className={styles.optionSub}>+250 руб.</div>
                            </div>
                            <label className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={editedConfig.options.delivery}
                                    onChange={() =>
                                        handleOptionChange('delivery', !editedConfig.options.delivery)
                                    }
                                />
                                <span className={styles.sliderSwitch}></span>
                            </label>
                        </div>
                        <div className={styles.optionCard}>
                            <div>
                                <div className={styles.optionLabel}>
                                    Рекомендации
                                    <span
                                        className={styles.tooltipIcon}
                                        data-tooltip="Подключение рекомендательной системы"
                                    >
                                        ?
                                    </span>
                                </div>
                                <div className={styles.optionSub}>+200 руб.</div>
                            </div>
                            <label className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={editedConfig.options.aiRecommendation}
                                    onChange={() =>
                                        handleOptionChange('aiRecommendation', !editedConfig.options.aiRecommendation)
                                    }
                                />
                                <span className={styles.sliderSwitch}></span>
                            </label>
                        </div>
                        <div className={styles.optionCard}>
                            <div>
                                <div className={styles.optionLabel}>
                                    Отключить корзину
                                    <span
                                        className={styles.tooltipIcon}
                                        data-tooltip="Перевести магазин в режим заявок"
                                    >
                                        ?
                                    </span>
                                </div>
                                <div className={styles.optionSub}>+450 руб.</div>
                            </div>
                            <label className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={editedConfig.options.noCart}
                                    onChange={() =>
                                        handleOptionChange('noCart', !editedConfig.options.noCart)
                                    }
                                />
                                <span className={styles.sliderSwitch}></span>
                            </label>
                        </div>
                        <div className={styles.optionCard}>
                            <div>
                                <div className={styles.optionLabel}>
                                    Группы товаров
                                    <span
                                        className={styles.tooltipIcon}
                                        data-tooltip="Управление группами товаров для акций и каталога"
                                    >
                                        ?
                                    </span>
                                </div>
                                <div className={styles.optionSub}>+500 руб.</div>
                            </div>
                            <label className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={editedConfig.options.groupManagement}
                                    onChange={() =>
                                        handleOptionChange('groupManagement', !editedConfig.options.groupManagement)
                                    }
                                />
                                <span className={styles.sliderSwitch}></span>
                            </label>
                        </div>
                        <div className={styles.optionCard}>
                            <div>
                                <div className={styles.optionLabel}>
                                    <span className={styles.labelText}>Отключение брендинга</span>
                                    <span
                                        className={styles.tooltipIcon}
                                        data-tooltip="Отключение лейбла Botique в каталоге"
                                    >
                                        ?
                                    </span>
                                </div>
                                <div className={styles.optionSub}>+50 руб.</div>
                            </div>
                            <label className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={editedConfig.options.removeLabel}
                                    onChange={() =>
                                        handleOptionChange('removeLabel', !editedConfig.options.removeLabel)
                                    }
                                />
                                <span className={styles.sliderSwitch}></span>
                            </label>
                        </div>
                        <div className={styles.optionCard}>
                            <div>
                                <div className={styles.optionLabel}>
                                    CRM
                                    <span
                                        className={styles.tooltipIcon}
                                        data-tooltip="Интеграция с CRM (например, amoCRM)"
                                    >
                                        ?
                                    </span>
                                </div>
                                <div className={styles.optionSub}>+1000 руб.</div>
                            </div>
                            <label className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={editedConfig.options.crm}
                                    onChange={() =>
                                        handleOptionChange('crm', !editedConfig.options.crm)
                                    }
                                />
                                <span className={styles.sliderSwitch}></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className={styles.baseFeaturesCard}>
                    <h3 className={styles.baseFeaturesTitle}>Базовое наполнение тарифа</h3>
                    <ul className={styles.baseFeaturesList}>
                        <li>Добавление баннеров</li>
                        <li>Загрузка товаров из шаблона</li>
                        <li>Выбор оформления магазина</li>
                        <li>Настройка формы оформления заказа</li>
                        <li>Выбор валюты магазина</li>
                        <li>Без ограничений посещений и заказов</li>
                    </ul>
                </div>
            </div>

            {isModalRecalcVisible && (
                <RecalculationModal
                    currentCost={currentConfig.currentPrice}
                    newCost={newTotalCost}
                    onConfirm={() => {
                        setModalRecalcVisible(false);
                        setModalConfirmVisible(true);
                    }}
                    onCancel={() => setModalRecalcVisible(false)}
                />
            )}
            <ConfirmModal isOpen={isModalConfirmVisible} onClose={() => { setModalConfirmVisible(false); }} onContinue={handleConnect} price={newTotalCost} period={period} />
        </div>
    );
};

export default TariffBlockV2;
