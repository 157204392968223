import moment from "moment";
import React, { useState } from "react";
import CustomCheckBox from "../../../components/CustomCheckBoxComponent";
import styles from "./TableComponent.module.css";

const Table = ({ usersData, setUsersData, handlerSortByDate, handlerSortByName }) => {
    const Tooltip = ({ items, position }) => {
        return (
            <div className={styles.tooltip} style={position}>
                <ul>
                    {items.map((item, index) => (
                        <li key={index}>{item.name} - {item.quantity} шт.</li>
                    ))}
                </ul>
            </div>
        );
    };


    const [selectAll, setSelectAll] = useState(false);
    const [tooltipItem, setTooltipItem] = useState({ user_id: '' }); // Хранит текущий элемент для отображения tooltip

    const updateUserSelected = (userId, newSelectedValue) => {
        const updatedItems = usersData.map(item => {
            if (item.user_id === userId) {
                return { ...item, selected: newSelectedValue };
            }
            return item;
        });
        setUsersData(updatedItems);
    };

    const handleCheckboxChange = (e, id) => {
        if (e.target.checked) {
            updateUserSelected(id, true);
        } else {
            updateUserSelected(id, false);
        }
    };

    const handleSelectAll = () => {
        if (!selectAll) {
            const updatedItems = usersData.map(item => {
                return { ...item, selected: true };
            });
            setUsersData(updatedItems);
            setSelectAll(true);
        } else {
            const updatedItems = usersData.map(item => {
                return { ...item, selected: false };
            });
            setUsersData(updatedItems);
            setSelectAll(false);
        }
    };

    const handleClearSelection = () => {
        const updatedItems = usersData.map(item => {
            return { ...item, selected: false };
        });
        setUsersData(updatedItems);
        setSelectAll(false);
    };

    // Установить текущий элемент для tooltip
    const handleMouseClick = (user) => {
        if (tooltipItem.user_id === user.user_id) {
            setTooltipItem({ user_id: '' });
        }
        else {
            setTooltipItem(user);
        }
    };



    return (
        <div className={styles.container}>
            <table className={styles.table}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <thead>
                        <tr>
                            <th style={{ padding: "10px", position: "relative" }}>Имя пользователя <button className={styles.sortBtn} onClick={handlerSortByName}></button></th>
                            <th style={{ padding: "10px", position: "relative" }}>
                                <span className={styles.lastVisitTh}>Последний визит</span>
                                <button className={styles.sortBtn} onClick={handlerSortByDate}></button>
                            </th>
                            <th style={{ padding: "10px", textAlign: "right" }}><span style={{ width: "100%" }}>Товаров в корзине</span></th>
                        </tr>
                    </thead>
                </table>
                <div className={styles.userList}>
                    <table style={{ borderCollapse: "collapse", width: "100%", overflow: "hidden" }}>
                        <tbody>
                            {usersData.map((item) => (
                                <>
                                    <tr key={item.user_id} className={styles.item}
                                    >
                                        <td className={styles.itemUsername}>
                                            <CustomCheckBox
                                                checked={item.selected}
                                                onChange={(e) => handleCheckboxChange(e, item.user_id)}
                                            />
                                            <span>{item.username === '' || item.username === undefined ? 'отсутствует' : item.username.length >= 17 ? (<a href={`https://t.me/${item.username}`} target="_blank">{item.username.slice(0, 17)}...</a>) : (<a href={`https://t.me/${item.username}`} target="_blank">{item.username}</a>)}</span>
                                        </td>
                                        <td>{moment(item.updated_at).format('HH:mm DD.MM.YYYY')}</td>
                                        <td style={{ marginRight: (item.cart_items_count === 0 && 22) }}>
                                            {item.cart_items_count}
                                            {item.products.length > 0 && <button className={styles.toolTipBtn} onClick={() => handleMouseClick(item)}>i</button>}
                                        </td>
                                    </tr>
                                    {item.user_id === tooltipItem.user_id && ( // Проверяем, есть ли элемент для отображения tooltip
                                        <Tooltip items={tooltipItem.products} position={{ top: '50px', left: '20px' }} />)}

                                </>

                            ))}
                        </tbody>
                    </table>
                </div>

            </table >
            <div className={styles.buttons}>
                <button onClick={handleSelectAll}>{selectAll ? "Снять выбор" : "Выбрать всех"}</button>
                <button onClick={handleClearSelection}>Очистить выбор</button>
            </div>
        </div >
    );
};

export default Table;
