import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';
import profile from "../assets/61.png";
import styles from "../styles/ProfileDropDownComponent_v2.module.css";

const ProfileDropdown = () => {
  const navigator = useNavigate();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleExit = () => {
    api.logoutAPI().then((response) => {
      localStorage.clear();
      navigator('/');
      window.location.reload();
    }).catch(error => { console.log(error); });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <button className={styles.dropdownToggle} onClick={toggleDropdown} type="button">
        <img src={profile} alt=''></img>
        <span>{'Профиль'}</span>
      </button>
      {isOpen && (
        <ul className={styles.dropdownMenu}>
          <li>
            Профиль
          </li>
          <li onClick={() => navigator('/profile')}>
            Настройки
          </li>
          <li onClick={() => navigator('/tariff')}>
            Конструктор тарифа
          </li>
          <li onClick={() => handleExit()}>
            Выйти
          </li>
        </ul>
      )}
    </div>
  );
};

export default ProfileDropdown;
