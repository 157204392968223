import { React, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import api from '../../../api/api';
import quest from "../../../assets/Outline.png";
import CustomCheckBox from "../../../components/CustomCheckBoxComponent";
import CustomFileInput from "../../../components/CustomInputComponent_v2";
import Loading from "../../../components/Loading";
import { error, success } from "../../../components/notification";
import styles from "./InfoShopComponent_v2.module.css";
import Phone from "./PhoneComponent_v2";

function InfoShop() {
    const { storeID } = useParams();
    const initialStoreInfo = {
        name: '',
        description: '',
        bot_token: 'BOTIQUE',
        bot_username: 'BotiqueShopBot',
        bot_name: '',
        boxberry_token: '',
        is_delivery_free: false,
        href: '',
        delivery_price: 0,
    };

    const [isDeliveryBlock, setDeliveryBlock] = useState(false);
    const [isBotBlock, setBotBlock] = useState(false);
    const [showBotForm, setBotForm] = useState(false);
    const [storeInfo, setStoreInfo] = useState(initialStoreInfo);
    const [previewUrl, setPreviewUrl] = useState(null);

    const [deliveryLocation, setDeliveryLocation] = useState('');

    const [shopStatus, setShopStatus] = useState('active');

    const [isLoading, setIsLoading] = useState(false);

    const [showInfo, setShowInfo] = useState(false);
    const [showInfo2, setShowInfo2] = useState(false);
    const [showInfo3, setShowInfo3] = useState(false);

    const [deliveryOptions, setDeliveryOptions] = useState({
        selfPickup: false,
        delivery: false,
        boxberry: false,
        online: false
    });
    const [additionalInfo, setAdditionalInfo] = useState({
        nearby: false
    })

    const nav = useNavigate();

    const handleCheckboxChange = (checkboxName) => {
        if (checkboxName === "additional") {
            setAdditionalInfo({
                nearby: !additionalInfo.nearby
            })
        }
        if (checkboxName === 'online') {
            setDeliveryOptions(prev => ({
                ...prev,
                selfPickup: false,
                delivery: false,
                boxberry: false,
            }));
        } else {
            setDeliveryOptions(prev => ({
                ...prev,
                online: false,
            }));
        }
        setDeliveryOptions((prevState) => ({
            ...prevState,
            [checkboxName]: !prevState[checkboxName],
        }));
    };

    const handleInputChange = (event) => {
        var { name, value } = event.target;

        if (name === "bot_token") {
            if (value) {
                api.checkTelegramToken(value).then((r) => {
                    if (r.status) {
                        if (r.data.ok) {
                            setStoreInfo((prevStoreInfo) => ({
                                ...prevStoreInfo,
                                "bot_username": r.data.result.username,
                            }));
                        }
                    } else { error('Вы ввели неверный токен бота'); }
                });
            }
        }

        if (name === "delivery_price") {
            if (isNaN(value)) { return; }
            value = Number(value);
            if (value < 0) { error('Стоимость доставки не может быть меньше 0'); return; }
        }

        setStoreInfo((prevStoreInfo) => ({
            ...prevStoreInfo,
            [name]: value,
        }));
    };

    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const handlePhotoSelect = (file) => {
        setSelectedPhoto(file);
    };

    const checkTG = async (token) => {
        try {
            const responseToken1 = await api.checkTelegramToken(token);
            let isToken1Valid = responseToken1.data.ok;

            if (!isToken1Valid) {
                isToken1Valid = responseToken1.response.data.ok;
            }

            return isToken1Valid;
        } catch (error) {
            console.error('Ошибка при проверке токенов:', error);
            return false;
        }
    };

    const checkBoxberry = async (token) => {
        try {
            const responseToken2 = await api.checkBoxberryToken(token);
            const isToken2Valid = responseToken2.data.valid;

            return isToken2Valid;
        } catch (error) {
            return false;
        }
    };

    const handleSave = () => {
        const atLeastOneChecked = Object.values(deliveryOptions).some((value) => value);
        if (!atLeastOneChecked) { error('Выберите хотя бы один вариант доставки'); return; }

        checkTG(storeInfo.bot_token).then(response => {
            // if (!response) { error('Токен бота введен неверно'); return; }

            let deliveryCode = '';
            if (deliveryOptions.selfPickup) deliveryCode += '1';

            if (deliveryOptions.boxberry) {
                if (!checkBoxberry(storeInfo.boxberry_token)) { error('Токен Boxberry введен неверно'); return; }
                deliveryCode += '2';
            }

            if (deliveryOptions.delivery) deliveryCode += '3';
            if (deliveryOptions.online) deliveryCode += '4';

            const newData = {
                additional_info: {
                    nearby: additionalInfo.nearby
                },
                delivery: {
                    delivery_type: deliveryCode,
                    delivery_option: deliveryLocation,
                    boxberry_token: storeInfo.boxberry_token,
                    is_delivery_free: storeInfo.is_delivery_free,
                    delivery_price: storeInfo.delivery_price,
                },
                store: {
                    name: storeInfo.name,
                    bot_token: storeInfo.bot_token,
                    bot_username: storeInfo.bot_username,
                    bot_name: storeInfo.bot_name === '' ? 'Без названия' : storeInfo['bot_name'],
                    description: storeInfo.description,
                    state: shopStatus,
                    template_code: localStorage.getItem('template_code'),
                    picture_url: null
                }
            };

            setIsLoading(true);
            if (selectedPhoto) {
                const newPictureID = uuid();
                const task = `{"request_id": "f80688de", "expires": "2032-07-12T14:14:18Z", "operations": [ { "export": [{ "bucket": "botique", "key": "stores/${newPictureID}.jpg" }] } ] }`;
                var formdata = new FormData();
                formdata.append("task", task);
                formdata.append("uploadFile", selectedPhoto);
                api.uploadImg(formdata).catch((error) => { error('При сохранении картинки произошла ошибка'); });
                newData.store.picture_url = `https://storage.yandexcloud.net/botique/stores/${newPictureID}.jpg`;
            } else {
                newData.store.picture_url = previewUrl;
            }

            if (storeID === "new") {
                api.createStore(newData).then((response) => {
                    setIsLoading(false);
                    console.log(response.status)
                    if (response.status === 201) {
                        success('Магазин успешно создан!');
                        const timer = setTimeout(() => { nav('/stores'); }, 500);
                        return () => clearTimeout(timer);
                    } else {
                        if (response.response.status === 403)
                            error(response.response.data.details);
                        else
                            error('Не удалось создать магазин. Проверьте данные и попробуйте снова');
                    }
                }).catch(error => { setIsLoading(false); });
            } else {
                api.updateStore(storeID, newData).then((response) => {
                    setIsLoading(false);
                    if (response.status === 200)
                        success('Данные магазина обновлены!');
                    else
                        error('Проверьте введенные данные');
                }).catch(error => { setIsLoading(false); });
            }
        });
    };

    useEffect(() => {
        const savedConfig = localStorage.getItem("_sub_config");
        if (savedConfig) {
            const parsedConfig = JSON.parse(savedConfig);
            if (parsedConfig?.delivery) {
                setDeliveryBlock(true);
            }
        }

        if (storeID !== "new") {
            localStorage.setItem('current_store_id', storeID);

            api.getStore(storeID).then((response) => {
                if (response.code === "ERR_BAD_REQUEST") {
                    error(JSON.stringify(response.response.data));
                } else {
                    let storeData = response.data['store'];
                    if (storeData.bot_token === "BOTIQUE")
                        storeData.bot_token = '';
                    storeData['boxberry_token'] = response.data['delivery']["boxberry_token"];
                    storeData['is_delivery_free'] = response.data['delivery']["is_delivery_free"];
                    storeData['delivery_price'] = response.data['delivery']["delivery_price"];
                    setAdditionalInfo(response.data.additional_info);
                    setStoreInfo(storeData);
                    setShopStatus(response.data['store']['state']);
                    setDeliveryLocation(response.data['delivery']["delivery_option"]);

                    localStorage.setItem('template_code', response.data.store.template_code);
                    setPreviewUrl(storeData.picture_url);

                    setDeliveryOptions((prevState) => ({
                        ...prevState,
                        selfPickup: response.data['delivery']["delivery_type"].includes('1'),
                        boxberry: response.data['delivery']["delivery_type"].includes('2'),
                        delivery: response.data['delivery']["delivery_type"].includes('3'),
                        online: response.data['delivery']["delivery_type"].includes('4'),
                    }));

                    setBotBlock(response.data['status']);
                    setBotForm(storeData.bot_token !== "" ? true : false);
                }
            }).catch(error => { console.log(error) });
        } else {
            api.storePrefill().then(r => {
                setStoreInfo((prevStoreInfo) => ({
                    ...prevStoreInfo,
                    boxberry_token: r.data.boxberry_token,
                }));
            });
        }
    }, [storeID]);

    useEffect(() => {
        document.body.classList.add(styles.bodyRuleDisappear);

        return () => {
            document.body.classList.remove(styles.bodyRuleDisappear);
        };
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <form className={styles.container} onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                <h1 className={styles.containerTitle}>Заполнение магазина</h1>
                <div className={styles.formContainer}>
                    <div className={styles.form} >
                        <div className={styles.formColumn}>
                            <div className={styles.formGroup}>
                                <div className={styles.formGroupTitle}>
                                    <div className={styles.number}>
                                        1
                                    </div>
                                    <span>Название магазина*</span>
                                </div>
                                <input type="text" name="name" value={storeInfo.name} onChange={handleInputChange} placeholder="Название магазина" required></input>
                            </div>
                            <div className={styles.formGroup}>
                                <div className={styles.formGroupTitle}>
                                    <div className={styles.number}>2</div>
                                    <span>Описание магазина</span>
                                </div>
                                <textarea value={storeInfo.description} name="description" onChange={handleInputChange} placeholder="Краткое описание вашего магазина, деятельности, особенностей, контактов, уникальных предложений и тд."></textarea>
                            </div>
                            <div className={styles.formGroup}>
                                <div className={styles.formGroupTitle}>
                                    <div className={styles.number}>3</div>
                                    <span>Логотип</span>
                                </div>
                                <div className={styles.logoInfo}>
                                    {previewUrl && <img src={previewUrl} alt="" />}
                                    <p>Обратите внимание: рекомендуемое соотношение сторон для логотипа 1:1</p>
                                </div>
                                <CustomFileInput onSelect={handlePhotoSelect} setPreviewUrl={setPreviewUrl} />
                            </div>
                        </div>
                        <div className={styles.formColumn}>
                            {isBotBlock &&
                                <div className={styles.formGroup}>
                                    <div className={styles.formGroupTitle}>
                                        <span>Подключение бота</span>
                                    </div>
                                    <div className={styles.listItem}>
                                        <CustomCheckBox outId={'bot_set'} type="checkbox" checked={showBotForm} onChange={() => { setBotForm(!showBotForm); handleInputChange({ target: { name: "", value: "" } }) }} />
                                        <span><label htmlFor={`bot_set`}>Подключить своего бота</label></span>
                                    </div>
                                    {showBotForm &&
                                        <>
                                            <div className={styles.formGroupTitle}>
                                                <span>Токен бота <img src={quest} alt="" onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)} style={{ position: 'relative', display: 'inline-block', cursor: "pointer" }} /></span>
                                            </div>
                                            <input type="text" name="bot_token" value={storeInfo.bot_token} onChange={handleInputChange} placeholder="Токен бота"></input>
                                        </>
                                    }
                                    {showInfo && (
                                        <div className={styles.infoNotification}>
                                            <p>Смотрите инструкцию "Как создать бота в телеграмм" в разделе поддержка</p>
                                        </div>
                                    )}
                                </div>
                            }

                            {/* <div className={styles.formGroup}>
                                <div className={styles.formGroupTitle}>
                                    <span>Username бота <img src={quest} alt="" onMouseEnter={() => setShowInfo2(true)} onMouseLeave={() => setShowInfo2(false)} style={{ cursor: "pointer" }} /></span>
                                </div>
                                <input type="text" name="bot_username" disabled value={storeInfo.bot_username} onChange={handleInputChange} placeholder="Username бота"></input>
                            </div>
                            {showInfo2 && (
                                <div className={styles.infoNotification2}>
                                    <p>Заполняется автоматически после ввода правильного токена</p>
                                </div>
                            )}  */}

                            <div className={styles.formGroup}>
                                <div className={styles.formGroupTitle}>
                                    <span>Способ доставки товаров</span>
                                </div>
                                <div className={styles.deliveryOptions}>
                                    <div className={styles.listItem}>
                                        <CustomCheckBox outId={'delivery'} type="checkbox" checked={deliveryOptions.selfPickup} onChange={() => handleCheckboxChange('selfPickup')}></CustomCheckBox>
                                        <span><label htmlFor={`delivery`}>Самовывоз</label></span>
                                    </div>

                                    {deliveryOptions.selfPickup ? (
                                        <div className={styles.formGroup}>
                                            <div className={styles.formGroupTitle}>
                                                <span>Адрес для самовывоза*</span>
                                            </div>
                                            <input id='selfPickInput' type="text" placeholder="Адрес для самовывоза" value={deliveryLocation} onChange={(e) => setDeliveryLocation(e.target.value)} required></input>
                                        </div>
                                    ) : null}

                                    {isDeliveryBlock && <div className={styles.listItem}>
                                        <CustomCheckBox outId='delivery2' checked={deliveryOptions.boxberry} onChange={() => handleCheckboxChange('boxberry')}></CustomCheckBox>
                                        <span><label htmlFor={`delivery2`}>Доставка Boxberry</label></span>
                                    </div>}

                                    {deliveryOptions.boxberry && (
                                        <>
                                            <div className={styles.formGroup}>
                                                <div className={styles.formGroupTitle}>
                                                    <span>Токен магазина Boxberry* <img src={quest} alt="" onMouseEnter={() => setShowInfo3(true)} onMouseLeave={() => setShowInfo3(false)} style={{ position: 'relative', display: 'inline-block', cursor: "pointer" }} /></span>
                                                </div>
                                                <input type="text" name="boxberry_token" value={storeInfo.boxberry_token} onChange={handleInputChange} placeholder="Токен магазина Boxberry" required></input>
                                                {showInfo3 && (
                                                    <div className={styles.infoNotification3}>
                                                        <p>Получите токен продавца в личном кабинете Boxberry- подробнее смотрите в разделе "Поддержка"</p>
                                                    </div>
                                                )}
                                                <div className={styles.isFreeBlock}>
                                                    <span>Стоимость доставки включена в стоимость товаров?</span>
                                                    <div className={styles.check}>
                                                        Нет<input className={styles.isFreeBlockRemember} type="checkbox" checked={storeInfo.is_delivery_free} onChange={() => { setStoreInfo(prev => ({ ...prev, is_delivery_free: !prev.is_delivery_free })) }}></input>Да
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div className={styles.listItem}>
                                        <CustomCheckBox outId={'delivery3'} checked={deliveryOptions.delivery} onChange={() => handleCheckboxChange('delivery')}></CustomCheckBox>
                                        <span><label htmlFor={`delivery3`}>Своя доставка</label></span>
                                    </div>

                                    {deliveryOptions.delivery && (
                                        <>
                                            <div className={styles.formGroup}>
                                                <div className={styles.formGroupTitle}>
                                                    <span>Стоимость доставки ({localStorage.getItem("current_store_currency") === null || localStorage.getItem("current_store_currency") === undefined || localStorage.getItem("current_store_currency") === "" ? "RUB" : localStorage.getItem("current_store_currency")})</span>
                                                </div>
                                                <input type="text" name="delivery_price" value={storeInfo.delivery_price} onChange={handleInputChange} placeholder="Стоимость доставки" required></input>
                                            </div>
                                        </>
                                    )}

                                    <div className={styles.listItem}>
                                        <CustomCheckBox outId={'delivery4'} checked={deliveryOptions.online} onChange={() => handleCheckboxChange('online')}></CustomCheckBox>
                                        <span><label htmlFor={`delivery4`}>Цифровые товары / услуги</label></span>
                                    </div>

                                </div>
                            </div>
                            <div className={styles.formGroup}>
                                <div className={styles.formGroupTitle}>
                                    <span>Подключение программы лояльности</span>
                                </div>
                                <div className={styles.deliveryOptions}>
                                    <div className={styles.listItem}>
                                        <CustomCheckBox outId={"nearby"} checked={additionalInfo.nearby} onChange={() => handleCheckboxChange('additional')}></CustomCheckBox>
                                        <span><label htmlFor={`nearby`}>Согласен участвовать в программе лояльности <a href="https://wenearby.ru" target="_blank" style={{ color: "#3CADFF" }}>Nearby</a></label></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.phoneContainer}>
                            <Phone name={storeInfo.name} description={storeInfo.description} logo={previewUrl} />
                            <Link to={`/store/${storeID}/set-design`} className={styles.goTo}>Перейти к выбору оформления магазина</Link>
                        </div>
                    </div>
                </div >

                <button className={styles.saveBtn} type="submit">Сохранить все изменения</button>
                {isLoading && <Loading />}
            </form>
        </div>
    )
}
export default InfoShop;