import React, { useEffect, useState } from "react";
import CategoriesSubcategoriesComponent from "../../components/CategoriesSubcategoriesComponent";
import Footer from "../../components/FooterComponent_v2";
import Header from "../../components/HeaderComponent_v2";
import { error } from "../../components/notification";
import api from "./api/api";

export default function CategoriesPage() {

    const [categories, setCategories] = useState([]);


    const handleCategoryCheckBoxChange = (categoryId) => {
        const activeCategories = categories.filter(category => category.state);
        const isLastActive = activeCategories.length === 1 && activeCategories[0].id === categoryId;

        if (isLastActive) {
            error("Должна быть выбрана хотя бы одна категория");
            return;
        }

        const currentCategory = categories.find(category => category.id === categoryId);
        const newCategoryState = !currentCategory.state;

        api.updateStoreCategory(newCategoryState, categoryId).then(() => {
            if (newCategoryState) {
                const updatedSubcategories = currentCategory.subcategories.map((subcategory) => ({
                    ...subcategory,
                    state: true
                }));

                const updateSubCategoryRequests = updatedSubcategories.map((subcategory) =>
                    api.updateStoreSubCategory(true, categoryId, subcategory.id)
                );

                Promise.all(updateSubCategoryRequests).then(() => {
                    setCategories((prevCategories) =>
                        prevCategories.map((category) => {
                            if (category.id === categoryId) {
                                return {
                                    ...category,
                                    state: newCategoryState,
                                    subcategories: updatedSubcategories,
                                };
                            }
                            return category;
                        })
                    );
                });
            } else {
                setCategories((prevCategories) =>
                    prevCategories.map((category) => {
                        if (category.id === categoryId) {
                            const updatedSubcategories = category.subcategories.map((subcategory) => ({
                                ...subcategory,
                                state: false
                            }));

                            return {
                                ...category,
                                state: newCategoryState,
                                subcategories: updatedSubcategories,
                            };
                        }
                        return category;
                    })
                );
            }
        });
    };

    const handleSubCategoryCheckBoxChange = (categoryId, subCategoryId) => {
        const currentCategory = categories.find(category => category.id === categoryId);
        const currentSubCategory = currentCategory.subcategories.find(subcategory => subcategory.id === subCategoryId);
        const activeCatego = categories.filter(category => category.state);
        const isLastActiveCat = activeCatego.length === 1 && activeCatego[0].id === categoryId;
        const activesSubs = currentCategory.subcategories.filter(subcategory => subcategory.state);
        const isLastActiveSub = activesSubs.length === 1 && activesSubs[0].id === subCategoryId;
        if (isLastActiveCat && isLastActiveSub) {
            error("Должна быть выбрана хотя бы одна подкатегория");
            return;
        }
        const preUpdatedSubcategories = currentCategory.subcategories.map((subcategory) =>
            subcategory.id === subCategoryId
                ? { ...subcategory, state: subcategory.state }
                : subcategory
        );

        api.updateStoreSubCategory(!currentSubCategory.state, categoryId, subCategoryId).then(() => {
            const updatedSubcategories = currentCategory.subcategories.map((subcategory) =>
                subcategory.id === subCategoryId
                    ? { ...subcategory, state: !subcategory.state }
                    : subcategory
            );

            const isAnySubCategorySelected = updatedSubcategories.some(subcategory => subcategory.state);
            const allSubCategoriesDeselected = updatedSubcategories.every(subcategory => !subcategory.state);

            if (allSubCategoriesDeselected) {
                handleCategoryCheckBoxChange(categoryId);
            } else {
                setCategories((prevCategories) =>
                    prevCategories.map((category) => {
                        if (category.id === categoryId) {
                            return {
                                ...category,
                                subcategories: updatedSubcategories,
                                state: isAnySubCategorySelected,
                            };
                        }
                        return category;
                    })
                );
            }
        });
    };
    const createCategory = async (name) => {
        return await api.createStoreCategory(name);
    };

    const createSubCategory = async (name, categoryID) => {
        return await api.createStoreSubCategory(name, categoryID);
    };

    useEffect(() => {
        api.getStoreCategories().then((r) => {
            if (r.data) {
                setCategories(r.data);
            }
        });
    }, []);

    return (
        <>
            <Header title={"Управление категориями"} />
            <CategoriesSubcategoriesComponent categories={categories}
                setCategories={setCategories}
                handleCategoryCheckBoxChange={handleCategoryCheckBoxChange}
                handleSubCategoryCheckBoxChange={handleSubCategoryCheckBoxChange}
                createCategoryRequest={createCategory}
                createSubCategoryRequest={createSubCategory} />
            <Footer />
        </>

    )
}