import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import status from "../assets/status.svg";

import FormatNumbers from "../components/FormatNumbersComponent";
import styles from "../styles/ProductsMobileComponent.module.css";
import ImageComponent from "./ImageCustomComponet";
import PopupCustomComponent from "./PopupCustomComponent";
import { getCurrencySymbol } from "./currentCurrency";
function ProductsMobileItemComponent({ item, handleDelete, setIsInfo, setInfo }) {
    const nav = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(item.status);
    const [showStats, setShowStats] = useState(false);



    const handlerEditproducts = () => {
        nav(`/product/${item.id}`);
    }
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option) => {
        const newState = !selectedOption;
        api.updateProductStateV2(item.id, { 'state': newState })
            .then((r) => {
                if (r?.status === 200) {
                    setSelectedOption(prevState => newState);
                    setIsOpen(false);
                } else if (r?.response?.status === 400) {
                    setIsInfo(true); setInfo('Товар закончился и не может быть опубликован.');
                }
            })
            .catch((e) => { })
    };

    return (
        <div className={styles.product}>


            <div className={styles.nameImg}>
                <div className={styles.productImg}>
                    {item.first_photo_url ?
                        <div className={styles.productsImgContainer}>
                            <ImageComponent imagePath={item?.first_photo_url} className={styles.productActualImage} ourWidth={80} />
                            {item?.total_photos === 1 ? null : <span>{`+ ${item?.total_photos - 1} фото`}</span>}
                        </div> :
                        <div className={styles.productsImgContainer}>
                            <div className={styles.productEmptyImg}>
                                <span>нет фото</span>
                            </div>
                            {item?.total_photos === 1 ? null : <span>{`+ ${item?.total_photos} фото`}</span>}
                        </div>
                    }
                </div>
                <div className={styles.productName}>
                    <span onMouseEnter={() => setShowStats(true)} onMouseLeave={() => setShowStats(false)}>
                        {showStats && (
                            <div className={styles.statsNotification}>
                                {item.cartCount === 0 ? (<p>Нет статистики</p>) : (<p>Конверсия**: {item.order_count} / {item.cart_count}</p>)}
                            </div>
                        )}
                    </span>
                    <span>{item.name.length >= 70 ? item.name.slice(0, 10) + "..." : item.name}</span>
                </div>
            </div>
            <div className={styles.productStatus}>
                <span>Статус:</span>
                <div className={styles.dropdown}>
                    <button className={styles.dropdownToggle} onClick={toggleDropdown}>
                        {selectedOption ? 'Продается' : 'Не продается'}
                        <img src={status} alt='' />
                    </button>
                    {isOpen && (
                        <ul className={styles.dropdownMenu} onMouseLeave={toggleDropdown}>
                            {["Продается", "Не продается"].map((option, index) => (
                                <li key={index} onClick={(e) => {
                                    e.stopPropagation();
                                    selectOption(option)
                                }}
                                >
                                    {option}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

            </div>
            <div className={styles.productCat}>
                <span>Категория:</span>
                <span>{item.category_name}</span>
            </div>
            <div className={styles.productCat}>
                <span>Подкатегория:</span>
                <span>{item.subcategory_name}</span>
            </div>

            <div className={styles.productPriceContainer}>
                <div className={styles.productPrice}>
                    <span>Цена до скидки:</span>
                    <FormatNumbers number={`${item.price} ${getCurrencySymbol(localStorage.getItem("current_store_currency"))}`} styles={{ fontWeight: 400 }} />
                </div>
                <div className={styles.productSalePrice}>
                    <span>Цена со скидкой:</span>
                    <FormatNumbers number={`${item.total_price} ${getCurrencySymbol(localStorage.getItem("current_store_currency"))}`} styles={{ fontWeight: 400 }} />
                </div>
            </div>

            <button className={styles.productCorrect} onClick={handlerEditproducts}>Редактировать товар</button>

            <PopupCustomComponent triggerEl={<button className={styles.productDelete}>Удалить товар</button>} title={"Вы действительно хотите удалить товар?"} text={"После удаления восстановить товар не получится"} handler={handleDelete} paramsHandler={item.id} />
        </div>
    )
}
export default ProductsMobileItemComponent