import React from 'react';
import styles from './TariffActiveDate.module.css';

const TariffActiveDate = ({ activeUntil }) => {
    const dateObj = typeof activeUntil === 'string' ? new Date(activeUntil) : activeUntil;
    const formattedDate = dateObj.toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    });

    return (
        <div className={styles.container}>
            <div className={styles.dateCard}>
                <p className={styles.text}>
                    Тариф активен до <span className={styles.date}>{formattedDate}</span>
                </p>
            </div>
        </div>
    );
};

export default TariffActiveDate;
